import { useState, useEffect } from "react"
import { window } from "browser-monads" //npm i browser-monads

function useWindowScroll() {
  const [windowScroll, setWindowScroll] = useState({
    scrollX: 0,
    scrollY: 0,
  })

  useEffect(() => {
    function handleScroll() {
      setWindowScroll({
        scrollX: window.scrollX,
        scrollY: window.scrollY,
      })
    }
    window.addEventListener("scroll", handleScroll)
    handleScroll()
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return windowScroll
}

export default useWindowScroll
