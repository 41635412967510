import React from "react"
import { graphql } from "gatsby"
import { getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { RichText, Date } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';
import { Facebook, Linkedin, Twitter } from 'react-social-sharing'

import LayoutUpdate from "../../components/layout-update"
import Seo from "../../components/seo"
import HeroSectionBlogPage from "../../components/common/hero-section-blog-page";

const dateStyle = "text-xs mb-8"

const BlogPostTemplate = ({ data }) => {
  const doc = data.prismicBlogPost
  const imgSrc = getSrc(doc.data.post_image_for_linkedin_share)
  const imgHero = getImage(doc.data.page_hero_background)
  const datePosted = Date(doc.data.date_published)
  const formattedDate = new Intl.DateTimeFormat('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  }).format(datePosted);

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title ? doc.data.page_title : null}
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
      />
      <HeroSectionBlogPage
        imgSrc={imgHero ? imgHero : null}
        title={doc.data.page_title}
        imgPosition={doc.data.page_hero_background_position}
      />

      <div className="py-20">
        <div className="w-11/12 lg:w-10/12 max-w-screen-lg mx-auto">
          <p className={dateStyle}><span className="mr-2">Published:</span> {formattedDate}</p>
          <RichText
            render={doc.data.post_content.richText}
            htmlSerializer={htmlSerializer}
          />

          {doc.data.date_published === "1970-01-01"
            ?
            null
            :
            <>
              <h3 className='font-bold text-center text-xl text-site-green-button border-t-2 border-black mt-12 pt-4'>Share this post:</h3>

              <div className="flex flex-col md:flex-row items-center justify-center mt-2 mx-auto">
                <Linkedin link={`https://www.831b.com/blog/${doc.uid}`} />
                <Facebook link={`https://www.831b.com/blog/${doc.uid}`} />
                <Twitter link={`https://www.831b.com/blog/${doc.uid}`} />
              </div>
            </>
          }
        </div>
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(BlogPostTemplate)

export const BlogPostTemplateQuery = graphql`
  query($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        date_published
        page_short_description
        page_hero_background {
          gatsbyImageData(
            width: 1920
          )
        }
        page_hero_background_position
        post_excerpt
        post_content {
          richText
        }
        post_image_for_linkedin_share {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  }
`