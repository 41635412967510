import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { RichText } from "prismic-reactjs"
import styled from 'styled-components'

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import HeroSection831bPlan from "../components/sections/hero-section-831b-plan"
import HubspotForm from 'react-hubspot-form'

const SlantedDiv = styled.div`
  transform: skew(16deg); /* Adjust the angle to slant more or less */
  transform-origin: bottom right; /* Keeps the top-right corner in place */
`

const SlantedDivWhite = styled.div`
  transform: skew(16deg); /* Adjust the angle to slant more or less */
  transform-origin: bottom right; /* Keeps the top-right corner in place */
`

const Page831bPlanTemplate = ({ data }) => {
  const doc = data.prismicNew831BPlanPage
  const imgHero = getImage(doc.data.hero_section_background_image)
  const imgSrc = getSrc(doc.data.hero_section_background_image)
  const firstIcon = getImage(doc.data.first_plan_card_icon)
  const secondIcon = getImage(doc.data.second_plan_card_icon)
  const thirdIcon = getImage(doc.data.third_plan_card_icon)
  const fourthIcon = getImage(doc.data.fourth_plan_card_icon)
  const fifthIcon = getImage(doc.data.fifth_plan_card_icon)
  const sixthIcon = getImage(doc.data.sixth_plan_card_icon)
  const buttonOneLinkType = doc.data.button_link.link_type
  const buttonOneLinkUid = doc.data.button_link.uid
  const buttonOneLinkUrl = doc.data.button_link.url

  const StyledFirstCard = styled.div`
    background: #${doc.data.first_card_background_color ? doc.data.first_card_background_color : '1EA0A0'};
    color: #${doc.data.first_card_text_color ? doc.data.first_card_text_color : 'FFFFFF'};
  `
  const StyledSecondCard = styled.div`
    background: #${doc.data.second_card_background_color ? doc.data.second_card_background_color : '1EA0A0'};
    color: #${doc.data.second_card_text_color ? doc.data.second_card_text_color : 'FFFFFF'};
  `

  const StyledThirdCard = styled.div`
    background: #${doc.data.third_card_background_color ? doc.data.third_card_background_color : '1EA0A0'};
    color: #${doc.data.third_card_text_color ? doc.data.third_card_text_color : 'FFFFFF'};
  `

  const StyledButton = styled.button`
    background: #${doc.data.button_color1 ? doc.data.button_color1 : '1EA0A0'};
    color: #${doc.data.button_name_color1 ? doc.data.button_name_color1 : 'FFFFFF'};

    :hover {
      background: #${doc.data.button_hover_color1 ? doc.data.button_hover_color1 : '35AAAA'};
      color: #${doc.data.button_hover_text_color1 ? doc.data.button_hover_text_color1 : 'FFFFFF'};
    }
  `

  return (
    <LayoutUpdate>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />

      <HeroSection831bPlan 
        title={doc.data.hero_section_title}
        subTitle={doc.data.hero_section_sub_title}
        buttonName={doc.data.button_name}
        buttonColor={doc.data.button_color}
        buttonTextColor={doc.data.button_name_color}
        buttonHoverColor={doc.data.button_hover_color}
        buttonHoverTextColor={doc.data.button_hover_text_color}
        imgSrc={imgHero}
      />

      <div className="bg-white hidden lg:block">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex items-start justify-center mx-auto space-x-16 p-2'>
            <div className="w-7/12">
              <div className="z-20 flex flex-col items-start justify-start">
                <p className='text-site-gray-dark text-3xl text-left uppercase mb-4 tracking-widest'>{doc.data.first_section_top_title}</p>
                <h2 className="text-site-red text-5xl text-left font-extrabold tracking-wider mb-8">{doc.data.first_section_main_title}</h2>

                <RichText
                  render={doc.data.first_section_content.richText}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </div>
            <div className="bg-white w-5/12 max-w-2xl border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId={doc.data.first_section_form_id}
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute top-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={900}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div> 

      <div className="bg-white block lg:hidden">
        <div className="relative w-full pt-8 pb-10 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto space-x-0 p-2'>
            <div className="w-full xl:w-1/2">
              <div className="z-20 flex flex-col items-center justify-center">
                <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>{doc.data.first_section_top_title}</p>
                <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">{doc.data.first_section_main_title}</h2>

                <RichText
                  render={doc.data.first_section_content.richText}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </div>

            <div className="bg-white w-full border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId={doc.data.first_section_form_id}
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-0 right-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/get-started-bg-comp-final.png"
              width={550}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>

      <div className="bg-white">
        <div className="relative w-full flex flex-col items-center justify-center pt-8 pb-16 md:pt-16 md:pb-16">
          <div className="absolute top-0 w-6/12 md:w-3/12 border-2 border-site-red"></div>

          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto'>
            <div className="z-20 flex flex-col items-center justify-center lg:items-start lg:justify-start">
              <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>{doc.data.second_section_top_title}</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">{doc.data.second_section_main_title}</h2>

              <RichText
                render={doc.data.second_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>

          <div className='w-11/12 max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-6 mx-auto mt-6'>
            <StyledFirstCard className="p-6 rounded-2xl">
              <h3 className="text-2xl text-center font-bold tracking-wider mb-6">{doc.data.first_card_title}</h3>
              <p className='text-base md:text-lg font-semibold text-center'>{doc.data.first_card_description}</p>
            </StyledFirstCard>

            <StyledSecondCard className="p-6 rounded-2xl">
              <h3 className="text-2xl text-center font-bold tracking-wider mb-6">{doc.data.second_card_title}</h3>
              <p className='text-base md:text-lg font-semibold text-center'>{doc.data.second_card_description}</p>
            </StyledSecondCard>

            <StyledThirdCard className="p-6 rounded-2xl">
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider mb-6">{doc.data.third_card_title}</h3>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.third_card_description}</p>
            </StyledThirdCard>
          </div>
        </div> 
      </div> 

      <div className="relative bg-site-red py-6">
        <div className='w-11/12 max-w-screen-xl flex flex-col md:flex-row items-center justify-center space-x-6 space-y-6 mx-auto'>
          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
              <h3 className="text-6xl font-bold text-center">{doc.data.first_round_card_number}</h3>
              <span className="text-base uppercase text-center">{doc.data.first_round_card_title}</span>
            </div>
          </div>

          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
            <h3 className="text-6xl font-bold text-center">{doc.data.second_round_card_number}</h3>
            <span className="text-base uppercase text-center">{doc.data.second_round_card_title}</span>
            </div>
          </div>

          <div className="w-52 h-52 bg-white rounded-full p-2 z-10">
            <div className="w-48 h-48 flex flex-col items-center justify-center border-2 border-black rounded-full p-6">
              <h3 className="text-6xl font-bold text-center">{doc.data.third_round_card_number}</h3>
              <span className="text-base uppercase text-center">{doc.data.third_round_card_title}</span>
            </div>
          </div>
        </div>

        <SlantedDiv className="absolute bg-black w-16 md:w-20 left-0 top-0 bottom-0 z-0"></SlantedDiv>
        <SlantedDivWhite className="absolute bg-white w-10 md:w-16 left-20 md:left-24 top-0 bottom-0 z-0"></SlantedDivWhite>
      </div>

      <div id="plan-options" className="bg-white">
        <div className="relative w-full flex flex-col items-center justify-center pt-20 pb-16 md:pt-24 md:pb-16">

          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto'>
            <div className="z-20 flex flex-col items-center justify-center lg:items-start lg:justify-start">
              <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>{doc.data.fourth_section_top_title}</p>
              <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold tracking-wider mb-6">{doc.data.fourth_section_main_title}</h2>

              <RichText
                render={doc.data.fourth_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </div>

          <div className='w-11/12 max-w-screen-xl grid grid-cols-1 lg:grid-cols-3 gap-6 mx-auto mt-6'>
            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={firstIcon.alt}
                  image={firstIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.first_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.first_plan_card_description}</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={secondIcon.alt}
                  image={secondIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.second_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.second_plan_card_description}</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={thirdIcon.alt}
                  image={thirdIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.third_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.third_plan_card_description}</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={fourthIcon.alt}
                  image={fourthIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.fourth_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.fourth_plan_card_description}</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={fifthIcon.alt}
                  image={fifthIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.fifth_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.fifth_plan_card_description}</p>
            </div>

            <div className="flex flex-col items-center justify-center p-6">
              <div className="w-36 h-36 flex items-center justify-center mb-4">
                <GatsbyImage
                  alt={sixthIcon.alt}
                  image={sixthIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className="text-site-gray-dark text-2xl text-center font-bold tracking-wider uppercase">{doc.data.sixth_plan_card_title}</h3>
              <div className="w-8/12 border-2 border-site-red my-4 mx-auto"></div>
              <p className='text-base md:text-lg text-site-gray-dark font-semibold text-center'>{doc.data.sixth_plan_card_description}</p>
            </div>
          </div>

          <div className="flex items-center justify-center mt-8">
            {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
              ?
              <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                <StyledButton className="w-full md:w-auto font-display text-base md:text-lg tracking-wider px-8 py-3">
                  {doc.data.button_name1}
                </StyledButton>
              </Link>
              :
              buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
              ?
              <a
                href={buttonOneLinkUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButton className="w-full md:w-auto font-display text-base md:text-lg tracking-wider px-8 py-3">
                  {doc.data.button_name1}
                </StyledButton>
              </a>
              :
              null
            }
          </div>
        </div>        
      </div>

      <div className="bg-white hidden lg:block">
        <div className="relative w-full pt-8 pb-16 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex items-start justify-center mx-auto space-x-16 p-2'>
            <div className="w-7/12">
              <div className="z-20 flex flex-col items-start justify-start">
                <p className='text-site-gray-dark text-3xl text-left uppercase mb-4 tracking-widest'>{doc.data.sixth_section_top_title}</p>
                <h2 className="text-site-red text-5xl text-left font-extrabold tracking-wider mb-8">{doc.data.sixth_section_main_title}</h2>

                <RichText
                  render={doc.data.sixth_section_content.richText}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </div>
            <div className="bg-white w-5/12 max-w-2xl border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId={doc.data.sixth_section_form_id}
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-20 lg:bottom-0 left-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
              width={490}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div> 

      <div className="bg-white block lg:hidden">
        <div className="relative w-full pt-8 pb-10 md:pt-16 md:pb-14">
          <div className='w-11/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto space-x-0 p-2'>
            <div className="w-full xl:w-1/2">
              <div className="z-20 flex flex-col items-center justify-center">
                <p className='text-site-gray-dark text-lg md:text-3xl text-center lg:text-left uppercase mb-4 tracking-widest'>{doc.data.sixth_section_top_title}</p>
                <h2 className="text-site-red text-2xl md:text-5xl text-center lg:text-left font-extrabold uppercase tracking-wider mb-6">{doc.data.sixth_section_main_title}</h2>

                <RichText
                  render={doc.data.sixth_section_content.richText}
                  htmlSerializer={htmlSerializer}
                />
              </div>
            </div>

            <div className="bg-white w-full border-2 border-site-gray-dark rounded-3xl p-8 shadow-lg mx-auto z-20">
              <HubspotForm
                region='na1'
                portalId='23182726'
                formId={doc.data.sixth_section_form_id}
                loading={<div className='italic'>Loading request form...</div>}
              />
            </div>
          </div>

          <div className="absolute bottom-0 left-0 z-0">
            <StaticImage
              src="../../images/podcasts/backgrounds/hosted-by-bg-comp.png"
              width={490}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt=""
            />
          </div>
        </div> 
      </div>
    </LayoutUpdate>
  )
}

export default withPrismicPreview(Page831bPlanTemplate)

export const Page831bPlanTemplateQuery = graphql`
  query($id: String!) {
    prismicNew831BPlanPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        page_no_index
        hero_section_title
        hero_section_sub_title
        button_name
        button_color
        button_name_color
        button_hover_color
        button_hover_text_color
        hero_section_background_image {
          gatsbyImageData(width: 1920)
        }
        first_section_top_title
        first_section_main_title
        first_section_content {
          richText
        }
        first_section_form_id
        second_section_top_title
        second_section_main_title
        second_section_content {
          richText
        }
        first_card_title
        first_card_description
        first_card_background_color
        first_card_text_color
        second_card_title
        second_card_description
        second_card_background_color
        second_card_text_color
        third_card_title
        third_card_description
        third_card_background_color
        third_card_text_color
        third_section_background_color
        first_round_card_number
        first_round_card_title
        second_round_card_number
        second_round_card_title
        third_round_card_number
        third_round_card_title
        fourth_section_top_title
        fourth_section_main_title
        fourth_section_content {
          richText
        }
        first_plan_card_icon {
          gatsbyImageData(width: 130)
          alt
        }
        first_plan_card_title
        first_plan_card_description
        second_plan_card_icon {
          gatsbyImageData(width: 130)
        }
        second_plan_card_title
        second_plan_card_description
        third_plan_card_icon {
          gatsbyImageData(width: 130)
        }
        third_plan_card_description
        third_plan_card_title
        fourth_plan_card_icon {
          gatsbyImageData(width: 130)
        }
        fourth_plan_card_title
        fourth_plan_card_description
        fifth_plan_card_icon {
          gatsbyImageData(width: 130)
        }
        fifth_plan_card_title
        fifth_plan_card_description
        sixth_plan_card_icon {
          gatsbyImageData(width: 130)
        }
        sixth_plan_card_title
        sixth_plan_card_description
        button_name1
        button_color1
        button_name_color1
        button_hover_color1
        button_hover_text_color1
        button_link {
          uid
          link_type
          url
        }
        sixth_section_top_title
        sixth_section_main_title
        sixth_section_content {
          richText
        }
        sixth_section_form_id
      }
    }
  }
`