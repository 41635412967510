import * as React from "react";
import { CookieNotice } from 'gatsby-cookie-notice';
import UseGeolocation from '../common/UseGeolocation';  // Import the custom hook

const SectionCookieNoticeNew = () => {
  const location = UseGeolocation();
  const [showCookieBanner, setShowCookieBanner] = React.useState(false);

  React.useEffect(() => {
    if (location) {
      const euCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];  // List of EU country codes
      const isInEU = euCountries.includes(location.country);
      const isInCA = location.region === 'California';

      // Show cookie banner only for EU and California
      if (isInEU || isInCA) {
        setShowCookieBanner(true);
      }
    }
  }, [location]);

  return (
    <>
      {showCookieBanner && (
        <div className="hidden md:flex items-center justify-center">
          <CookieNotice
            backgroundClasses="bg-site-gray-dark opacity-90 flex flex-col lg:flex-row items-center md:justify-between p-4"
            backgroundWrapperClasses="w-9/12 md:w-7/12 xl:w-5/12 fixed bottom-0 z-20 py-0 mx-auto"
            acceptButtonClasses="border border-white hover:bg-site-red-hover hover:border-site-red-hover text-white text-sm md:text-base font-semibold mt-2 lg:mt-0 px-6 py-1"
            acceptButtonText="OK"
            declineButton={false}
            personalizeButtonEnable={false}
          >
            <p className="text-white text-sm md:text-base text-center xl:text-left">
              By using this website, you agree to our use of cookies.
            </p>
          </CookieNotice>
        </div>
      )}
    </>
  );
};

export default SectionCookieNoticeNew;