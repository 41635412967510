import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 144px;
  height: 61px;

  @media (max-width: 1023px) {
    width: 96px;
    height: 41.23px;
  }
`

const LogoSimple = () => {
  const data = useStaticQuery(graphql`
    query LogoSimpleQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismicGeneralSettings {
        data {
          site_title
          site_description
          logo {
            gatsbyImageData(
              width: 398
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const image = getImage(doc.data.logo)

  return (
    <div>
      <Link to="/">
        <h1>
          <StyledGatsbyImage 
            image={image} 
            alt={doc.data.site_title || data.site.siteMetadata?.title} 
            objectFit="contain"
            loading="eager"
            placeholder="none"
          />
        </h1>
      </Link>
    </div>
  )
}

export default LogoSimple