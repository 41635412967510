import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: grid;
  background: #333333D4;
  min-height: 400px;
`
const StyledDiv2 = styled.div`
  grid-area: 1/1;
  position: relative;
  place-items: center;
  display: grid;

  @media (max-width: 767px) {
    place-items: center;
  }

  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  opacity: 0.7;
  width: 100%;
  height: 500px;

  @media (max-width: 767px) {
    height: 600px;
  }
`

const HeroSectionCapVsMicroLanding = ({ imgSrc, leftTitle, midTitle, rightTitle, buttonOneTypeHover, buttonOneName, buttonOneNameHover, buttonOneLinkType, buttonOneLinkUrl, buttonOneLinkUid }) => {
  const data = useStaticQuery(graphql`
    query HeroSectionCapVsMicroLandingQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/home-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  return (
    <>
      <StyledDiv className="relative pt-14 xl:pt-20">
        <StyledGatsbyImage
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <StyledDiv2>
          <div className="w-11/12 lg:w-10/12 max-w-screen-xl flex flex-col items-center justify-center mx-auto">
            <div className="w-full flex flex-col items-center justify-center">
              <h2 className="text-white text-4xl lg:text-6xl font-extrabold text-center">
                {leftTitle ? leftTitle : "Left Title"} <span className="text-site-red">{midTitle ? midTitle : "Middle Title"}</span> {rightTitle ? rightTitle : "Right Title"}
              </h2>

              {buttonOneTypeHover
                ?
                <>
                  <div className="hidden xl:block mt-8 mb-0">
                    {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                      ?
                      <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                        <button className="w-full bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-12 py-3 mx-auto">
                          <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                          <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                        </button>
                      </Link>
                      :
                      buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                      ?
                      <a
                        href={buttonOneLinkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                          <span className="default-text mx-2">{buttonOneName ? buttonOneName : "Button Name"}</span>
                          <span className="hover-text">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                        </button>
                      </a>
                      :
                      null
                    }
                  </div>

                  <div className="block xl:hidden mt-6 mb-6">
                    <p className="text-white text-2xl font-semibold text-center tracking-wider mb-6">{buttonOneName ? buttonOneName : "Button Name"}</p>
                      {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                        ?
                        <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-14 py-3">
                            <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                          </button>
                        </Link>
                        :
                        buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                        ?
                        <a
                          href={buttonOneLinkUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-24 py-3">
                            <span className="mx-1">{buttonOneNameHover ? buttonOneNameHover : "Button Name Hover"}</span>
                          </button>
                        </a>
                        :
                        null
                      }
                  </div>
                </>
                :
                <>
                  {buttonOneLinkType === "Document" || buttonOneLinkType === "Any"
                    ?
                    <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-white uppercase tracking-wider px-10 py-3">
                        {buttonOneName ? buttonOneName : "Button Name"}
                      </button>
                    </Link>
                    :
                    buttonOneLinkType === "Web" || buttonOneLinkType === "Media"
                    ?
                    <Link to={buttonOneLinkUid ? `/${buttonOneLinkUid}` : "/"}>
                      <button className="w-full mb-4 xl:mb-0 md:w-auto bg-site-red opacity-70 hover:opacity-90 text-base md:text-lg text-whites uppercase tracking-wider px-10 py-3">
                        {buttonOneName ? buttonOneName : "Button Name"}
                      </button>
                    </Link>
                    :
                    null
                  }
                </>
              }
            </div>
          </div>
        </StyledDiv2>
      </StyledDiv>
    </>
  )
}

export default HeroSectionCapVsMicroLanding
