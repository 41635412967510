import React, { useState, useEffect } from "react";
import { CookieNotice } from 'gatsby-cookie-notice';
import UseGeolocation from '../common/UseGeolocation';  // Import the custom hook

const SectionCookieNoticeMobileNew = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const location = UseGeolocation();

  useEffect(() => {
    if (location) {
      const euCountries = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE']; // EU country codes
      const isInEU = euCountries.includes(location.country);
      const isInCA = location.region === 'California';

      // Show cookie banner only for EU and California
      if (isInEU || isInCA) {
        setShowCookieBanner(true);
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section');
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;
        setIsVisible(heroBottom < 0); // Update visibility based on hero position
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showCookieBanner && (
        <div className="flex md:hidden items-center justify-center">
          <CookieNotice
            backgroundClasses="bg-site-gray-dark opacity-90 flex flex-col items-center p-2"
            backgroundWrapperClasses={`w-full fixed bottom-0 z-20 py-0 mx-auto ${isVisible ? 'mb-20' : 'pb-6'}`} // Conditionally apply margin
            acceptButtonClasses="border border-white hover:bg-site-red-hover hover:border-site-red-hover text-white text-base font-semibold mt-2 px-8 py-1"
            acceptButtonText="OK"
            declineButton={false}
            personalizeButtonEnable={false}
          >
            <p className="text-white text-sm text-center">
              By using this website, you agree to our use of cookies.
            </p>
          </CookieNotice>
        </div>
      )}
    </>
  );
};

export default SectionCookieNoticeMobileNew;