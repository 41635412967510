import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { navigate } from '@reach/router'
import styled from "styled-components"

const StyledGatsbyImage = styled(GatsbyImage)`
  grid-area: 1/1;
  object-fit: cover;
  height: 800px;

  @media (max-width: 767px) {
    height: 500px;
  }
`
const SlantedDiv = styled.div`
  transform: skew(-10deg); /* Adjust the angle to slant more or less */
  transform-origin: top right; /* Keeps the top-right corner in place */
`

const HeroSection831bPlan = ({ imgSrc, title, subTitle, buttonName, buttonColor, buttonTextColor, buttonHoverColor, buttonHoverTextColor }) => {
  const data = useStaticQuery(graphql`
    query HeroSection831bPlanQuery {
      imgDefault: file(relativePath: { eq: "backgrounds/831b-plan-hero-bg.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const imgDefault = getImage(data.imgDefault)

  const StyledButton = styled.button`
    background: #${buttonColor ? buttonColor : '1EA0A0'};
    color: #${buttonTextColor ? buttonTextColor : 'FFFFFF'};

    :hover {
      background: #${buttonHoverColor ? buttonHoverColor : '35AAAA'};
      color: #${buttonHoverTextColor ? buttonHoverTextColor : 'FFFFFF'};
    }
  `

  return (
    <>
      <div className="relative grid pt-14 xl:pt-20 z-20" style={{ background: "#000000" }}>
        <StyledGatsbyImage
          alt="hero image"
          image={imgSrc ? imgSrc : imgDefault}
          formats={["auto", "webp", "avif"]}
        />

        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className="w-11/12 max-w-screen-xl flex flex-col items-start justify-start px-2 z-10">
            <div className="w-full md:w-10/12 xl:w-8/12 flex flex-col justify-center">
              <h2 className="text-white text-4xl lg:text-6xl font-extrabold mb-8">{title ? title : 'Title'}</h2>
            </div>

            <div className="w-4/12 border border-white mb-6"></div>

            <div className="w-9/12 md:w-9/12 lg:w-7/12 xl:w-5/12 flex flex-col justify-center">
              <h3 className="max-w-xl text-white text-xl md:text-2xl font-bold mb-8">{subTitle ? subTitle : 'Sub Title'}</h3>
            </div>            

            <div className="w-9/12 md:w-6/12 xl:w-4/12 flex flex-col justify-center">
              <StyledButton 
                onClick={() => {navigate('#plan-options')}}
                className="w-full md:w-auto font-display text-base md:text-lg tracking-wider px-0 py-3">
                  {buttonName ? buttonName : 'Button Name'}
              </StyledButton>
            </div>
          </div>

          <SlantedDiv className="absolute left-0 bg-site-red w-full md:w-11/12 xl:w-8/12 h-full opacity-70 z-0"></SlantedDiv>
        </div>     
      </div>
    </>
  )
}

export default HeroSection831bPlan
