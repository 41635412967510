import { useEffect, useState } from 'react';

const UseGeolocation = () => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch(`https://ipinfo.io/json?token=bdd083f99d8cd4`); // Replace with your actual API key
        const data = await response.json();
        setLocation(data);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();
  }, []);

  return location;
};

export default UseGeolocation;