import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Popup from "reactjs-popup"
import styled from "styled-components"

const menuItem = "font-display text-white hover:text-site-red uppercase font-semibold"

const MenuItemsUpdate = ({ isHomePage, isTestiPage, isContactPage }) => {
  const data = useStaticQuery(graphql`
    query MenuItemsUpdateQueryNew {
      prismicGeneralSettings {
        data {
          drop_down_menu_name_1
          drop_down_menu_name_2
          drop_down_menu_name_3
          drop_down_menu_1 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          drop_down_menu_2 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          drop_down_menu_3 {
            menu_name
            menu_link {
              uid
              link_type
              url
            }
          }
          static_menu
          static_menu_link {
            uid
          }
        }
      }
      prismicCtaButtonSettings {
        data {
          main_color
          main_text_color
          hover_color
          hover_text_color
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const menuOneList = doc.data.drop_down_menu_1
  const menuTwoList = doc.data.drop_down_menu_2
  const menuThreeList = doc.data.drop_down_menu_3
  
  const button = data.prismicCtaButtonSettings
  const buttonColor = button.data.main_color ? button.data.main_color : 'bb0000'
  const buttonTextColor = button.data.main_text_color ? button.data.main_text_color : 'ffffff'
  const buttonHoverColor = button.data.hover_color ? button.data.hover_color : 'cf1716'
  const buttonHoverTextColor = button.data.hover_text_color ? button.data.hover_text_color : 'ffffff'

  const StyledButton = styled.button`
    background: #${buttonColor};
    color: #${buttonTextColor};

    :hover {
      background: #${buttonHoverColor};
      color: #${buttonHoverTextColor};
    }
  `

  return (
    <div className="hidden xl:flex items-center space-x-10 mr-4">
      {isHomePage
        ?
        null
        :
        <Link to="/">
          <div className="flex text-lg tracking-wider items-center">
            <span className={menuItem}>Home</span>
          </div>
        </Link>
      }

      <Popup
        trigger={
          <button type="button" className="inline-block font-display text-white text-lg tracking-wider uppercase font-semibold hover:text-site-red">SRA Plans</button>
        }
        position="bottom left"
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none", marginTop: "20px", marginLeft: "16px", }}
        arrow={false}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link
              to="/"
              className="hidden"
            >
              <div className="ml-4">
                <p className="font-display text-red-600 group-hover:text-white text-lg">Sample</p>
              </div>
            </Link>

            {menuOneList.map((item, index) => {
              return (
                item.menu_link.link_type === "Document" || item.menu_link.link_type === "Any"
                ?
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </Link>
                :
                item.menu_link.link_type === "Web" || item.menu_link.link_type === "Media"
                ?
                  <a
                    href={item.menu_link.url}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </a>
                :
                null
              )
            })}
          </div>
        </div>
      </Popup>

      {isTestiPage
        ?
        null
        :
        <Link to="/testimonials">
          <button type="button" className="inline-block font-display text-white text-lg tracking-wider uppercase font-semibold hover:text-site-red">Testimonials</button>
        </Link>
      }

      <Popup
        trigger={
          <button type="button" className="inline-block font-display text-white text-lg tracking-wider uppercase font-semibold hover:text-site-red">About</button>
        }
        position="bottom left"
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none", marginTop: "20px", marginLeft: "16px", }}
        arrow={false}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link
              to="/"
              className="hidden"
            >
              <div className="ml-4">
                <p className="font-display text-red-600 group-hover:text-white text-lg">Sample</p>
              </div>
            </Link>

            {menuTwoList.map((item, index) => {
              return (
                item.menu_link.link_type === "Document" || item.menu_link.link_type === "Any"
                ?
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </Link>
                :
                item.menu_link.link_type === "Web" || item.menu_link.link_type === "Media"
                ?
                  <a
                    href={item.menu_link.url}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </a>
                :
                null
              )
            })}
          </div>
        </div>
      </Popup>

      <Popup
        trigger={
          <button type="button" className="inline-block font-display text-white text-lg tracking-wider uppercase font-semibold hover:text-site-red">Resources</button>
        }
        position="bottom left"
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none", marginTop: "20px", marginLeft: "16px", }}
        arrow={false}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
            <Link
              to="/"
              className="hidden"
            >
              <div className="ml-4">
                <p className="font-display text-red-600 group-hover:text-white text-lg">Sample</p>
              </div>
            </Link>

            {menuThreeList.map((item, index) => {
              return (
                item.menu_link.link_type === "Document" || item.menu_link.link_type === "Any"
                ?
                  <Link
                    key={index}
                    to={item.menu_link.uid ? `/${item.menu_link.uid}` : "/"}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </Link>
                :
                item.menu_link.link_type === "Web" || item.menu_link.link_type === "Media"
                ?
                  <a
                    href={item.menu_link.url}
                    className="group -m-3 p-3 flex items-start hover:bg-site-red"
                  >
                    <div className="ml-4">
                      <p className="font-display text-red-600 group-hover:text-white text-lg">{item.menu_name ? item.menu_name : 'Menu Item'}</p>
                    </div>
                  </a>
                :
                null
              )
            })}
          </div>
        </div>
      </Popup>

      {isContactPage
        ?
        null
        :
        <Link to={doc.data.static_menu_link ? `/${doc.data.static_menu_link.uid}` : "/"}>
          <StyledButton className="w-full md:w-auto font-display font-semibold text-base tracking-wider px-6 py-2">
            SPEAK TO AN 831(b) EXPERT
          </StyledButton>
        </Link>
      }
    </div>
  )
}

export default MenuItemsUpdate
