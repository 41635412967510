import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';

import HeaderFull from "./header/header-full";
import FooterNewUpdate from "./footer-new-update";
import SectionCookieNoticeNew from './sections/section-cookie-notice-new';
import SectionCookieNoticeMobileNew from './sections/section-cookie-notice-mobile-new';
import PopUp from '../components/common/PopUp';

const LayoutNew = ({ children, isContactPage }) => {
  const data = useStaticQuery(graphql`
    query LayoutNewQuery {
      prismicPromotionalPopUp {
        data {
          activate_pop_up
          top_title
          main_title
          short_description
          date
          button_name
          button_link
        }
      }      
      imgDefault: file(relativePath: { eq: "backgrounds/pop-up-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `);

  const pop = data.prismicPromotionalPopUp;
  const imgDefault = getImage(data.imgDefault);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [timeDelay, setTimeDelay] = useState(5000); // Time delay in milliseconds (e.g., 5000 = 5 seconds)

  useEffect(() => {
    const lastShown = localStorage.getItem('popupLastShown'); // Unified key
    const now = new Date().getTime();
  
    if (!lastShown || now - lastShown > 24 * 60 * 60 * 1000) {
      const timer = setTimeout(() => {
        setIsPopupOpen(true);
        localStorage.setItem('popupLastShown', now); // Same key for both layouts
      }, timeDelay);
  
      return () => clearTimeout(timer);
    }
  }, [timeDelay]);

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window.Chatlio) {
      window.Chatlio.init('d932bc71-e6bc-4d0a-7bfc-22ebd2283d28');
    }
  }, []);

  const openChat = (e) => {
    e.preventDefault();
    if (typeof window !== 'undefined' && window._chatlio) {
      window._chatlio.open();
    } else {
      console.error('Chatlio not initialized');
    }
  };

  return (
    <>
      <HeaderFull isContactPage={!!isContactPage} />
      <div>
        <main>{children}</main>
      </div>
      <chatlio-widget widgetid="d932bc71-e6bc-4d0a-7bfc-22ebd2283d28"></chatlio-widget>
      <FooterNewUpdate />
      <SectionCookieNoticeNew />
      <SectionCookieNoticeMobileNew />
      
      {/* Popup Component */}
      {pop.data.activate_pop_up && (
        <PopUp isOpen={isPopupOpen} onClose={closePopup} backgroundImage={imgDefault}>
          <div className="w-full flex flex-col items-center justify-center mx-auto">          
            <div className="z-10">            
              <h3 className="text-site-red text-xl md:text-2xl text-center font-bold uppercase mb-4">
                {pop.data.top_title || "Top Title Here"}
              </h3>

              <h2 className="text-site-red text-4xl md:text-6xl text-center font-bold uppercase mb-8">
                {pop.data.main_title || "Main Title Here"}
              </h2>

              <p className="text-base md:text-xl mb-6 text-center leading-7 md:leading-9">
                {pop.data.short_description || "Short description here."}
              </p>

              <p className="text-xl md:text-3xl font-bold text-center uppercase mb-8">
                {pop.data.date || "January 1, 2000"}
              </p>

              <div className='flex flex-col items-center justify-center'>
                <a
                  href={pop.data.button_link || "https://www.831b.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={closePopup} // Close the popup when clicking the link
                >
                  <button className="w-full md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-16 py-3">
                    {pop.data.button_name || "Button Name"}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};

LayoutNew.propTypes = {
  children: PropTypes.node.isRequired,
  isContactPage: PropTypes.bool,
};

export default LayoutNew;