import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

const StyledDiv = styled.div`
  width: 144px;
  height: 61px;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 144px;
  height: 61px;
`

const LogoFooter = () => {
  const data = useStaticQuery(graphql`
    query LogoFooterQuery {
      site {
        siteMetadata {
          title
        }
      }
      prismicGeneralSettings {
        data {
          site_title
          site_description
          logo {
            gatsbyImageData(
              width: 398
            )
          }
        }
      }
    }
  `)

  const doc = data.prismicGeneralSettings
  const image = getImage(doc.data.logo)

  return (
    <StyledDiv>
      <Link to="/">
        <StyledGatsbyImage 
          image={image} 
          alt={doc.data.site_title || data.site.siteMetadata?.title} 
          objectFit="contain"
          loading="eager"
          placeholder="none"
        />
      </Link>
    </StyledDiv>
  )
}

export default LogoFooter